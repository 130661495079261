.Weather2 {
	.hide {
		display: none;
		border: 1px solid green;
	}
	.show {
		display: block;
		background-color: white;
		border: 1px solid red;
		color: black;

		li {
			cursor: pointer;
		}
	}
}
