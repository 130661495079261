// --- SASS VARIABLES ---
// https://bootswatch.com/  slate
// Fonts
// @import url("https://fonts.googleapis.com/css?family=Bree+Serif|Merriweather&display=swap");
// $font-main: "Merriweather", serif;
// $font-hightlight: "Bree Serif", serif;

// https://coolors.co/ff5e5b-d8d8d8-ffffea-00cecb-ffed66
/* SCSS HSL */
// $col_orange-red-crayola: hsla(1, 100%, 68%, 1);
// $col_gainsboro: hsla(0, 0%, 85%, 1);
// $col_ivory: hsla(60, 100%, 96%, 1);
// $col_middle-blue-green: hsla(173, 68%, 70%, 1);
// $col_robin-egg-blue: hsla(179, 100%, 40%, 1);
// $col_corn: hsla(53, 100%, 70%, 1);
// $col_granny-smith-apple: hsla(136, 59%, 69%, 1);
// $col_dark-red: rgb(153, 50, 50);

// $col_dark: hsla(0, 0%, 10%, 1);
// $col_light: hsl(180, 78%, 96%, 1);
// $col_light-gray: hsla(180, 10%, 80%, 1);
// $col_dark-gray: hsla(180, 10%, 20%, 1);

// // Color functions
// $col_success: $col_middle-blue-green;
// $col_default: $col_robin-egg-blue;
// $col_info: $col_dark;
// $col_warning: $col_orange-red-crayola;
// $col_danger: $col_dark-red;

// // Max-width
// $max_width: 1200px;

// // Space
// $space_header: 20px;
// $space_div: 40px 20px;

// // Misc
// $brd_main: 1px dotted $col_robin-egg-blue;

// Slate 5.2.2
// Bootswatch

$theme: "slate" !default;

//
// Color system
//

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #999 !default;
$gray-600: #7a8288 !default;
$gray-700: #52575c !default;
$gray-800: #3a3f44 !default;
$gray-900: #272b30 !default;
$black: #000 !default;

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #ee5f5b !default;
$orange: #fd7e14 !default;
$yellow: #f89406 !default;
$green: #62c462 !default;
$teal: #20c997 !default;
$cyan: #5bc0de !default;

$primary: $gray-800 !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-200 !default;
$dark: $gray-900 !default;

$min-contrast-ratio: 1.95 !default;

// Body

$body-bg: $gray-900 !default;
$body-color: #aaa !default;

// Links

$link-color: $white !default;

// Fonts

// Tables

$table-color: $white !default;
$table-accent-bg: rgba($white, 0.05) !default;
$table-hover-bg: rgba($white, 0.075) !default;
$table-border-color: rgba($black, 0.6) !default;
$table-dark-border-color: $table-border-color !default;
$table-dark-color: $white !default;

$table-bg-scale: 0 !default;

// Buttons

$input-btn-padding-y: 0.75rem !default;
$input-btn-padding-x: 1rem !default;

// Forms

$input-bg: $white !default;
$input-disabled-bg: #ccc !default;

$input-color: $gray-900 !default;

$form-check-input-bg: $white !default;

// Dropdowns

$dropdown-bg: $gray-800 !default;
$dropdown-border-color: rgba($black, 0.6) !default;
$dropdown-divider-bg: rgba($black, 0.15) !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: $white !default;
$dropdown-link-hover-bg: $body-bg !default;
$dropdown-link-active-color: $dropdown-link-hover-color !default;
$dropdown-link-active-bg: $dropdown-link-hover-bg !default;

// Navs

$nav-tabs-border-color: rgba($black, 0.6) !default;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color !default;
$nav-tabs-link-active-color: $white !default;
$nav-tabs-link-active-border-color: $nav-tabs-border-color !default;

// Navbar

$navbar-padding-y: 0 !default;
$navbar-dark-hover-color: $white !default;
$navbar-light-hover-color: $gray-800 !default;
$navbar-light-active-color: $gray-800 !default;

// Pagination

$pagination-color: $white !default;
$pagination-bg: transparent !default;
$pagination-border-color: rgba($black, 0.6) !default;
$pagination-hover-color: $white !default;
$pagination-hover-bg: transparent !default;
$pagination-hover-border-color: rgba($black, 0.6) !default;
$pagination-active-bg: transparent !default;
$pagination-active-border-color: rgba($black, 0.6) !default;
$pagination-disabled-bg: transparent !default;
$pagination-disabled-border-color: rgba($black, 0.6) !default;

// Cards

$card-border-color: rgba($black, 0.6) !default;
$card-cap-bg: lighten($gray-800, 10%) !default;
$card-bg: lighten($body-bg, 5%) !default;

// Popovers

$popover-bg: lighten($body-bg, 5%) !default;

// Toasts

$toast-background-color: lighten($body-bg, 5%) !default;
$toast-border-color: rgba(0, 0, 0, 0.2) !default;
$toast-header-color: $body-color !default;
$toast-header-background-color: $toast-background-color !default;
$toast-header-border-color: $toast-border-color !default;

// Modals

$modal-content-bg: lighten($body-bg, 5%) !default;
$modal-header-border-color: rgba(0, 0, 0, 0.2) !default;

// Progress bars

$progress-bg: darken($gray-900, 5%) !default;
$progress-bar-color: $gray-600 !default;

// List group

$list-group-color: $white !default;
$list-group-bg: lighten($body-bg, 5%) !default;
$list-group-border-color: rgba($black, 0.6) !default;
$list-group-hover-bg: lighten($body-bg, 10%) !default;
$list-group-active-color: $white !default;
$list-group-active-bg: $list-group-hover-bg !default;
$list-group-active-border-color: $list-group-border-color !default;
$list-group-disabled-color: $gray-700 !default;
$list-group-action-color: $white !default;

// Breadcrumbs

$breadcrumb-padding-y: 0.375rem !default;
$breadcrumb-padding-x: 0.75rem !default;
$breadcrumb-active-color: $gray-500 !default;
$breadcrumb-border-radius: 0.25rem !default;

// Code

$pre-color: inherit !default;
